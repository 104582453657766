.connect_wallet_div {
  text-align: center;
  width: 100%;
  display: inline-block;
  /* border: 1px solid #eee; */
  border-radius: 5px;
  margin: 10px;
  padding: 10px;
  cursor: pointer;
  transition: all .2s;
}
.connect_wallet_div > p {
  color: #3333;
}
.connect_wallet_div img {
  height: 40px;
  margin-bottom: 10px;
}
.btnSuccess{
    font-size: 1.2em;
    padding: 10px;
    color: #fff;
    border: 2px solid #c3921e;
    border-radius: 20px/50px;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.3s ease-out;
    position: absolute;
    top: 10%;
    left: 30%;
}
iframe{
  z-index: 0 !important;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
